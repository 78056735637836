import { useEffect, useRef, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import NoChat from '../../../assets/no-chat 1.png';

import {
  ChatMessage,
  ChatResponse,
  ChatResponseChunk
} from 'services/api/models';
import UserChatMessage from 'components/chat/UserChatMessage/UserChatMessage';
import ChatAnswer from 'components/chat/ChatAnswer/ChatAnswer';
import ChatAnswerError from 'components/chat/ChatAnswer/ChatAnswerError';
import ChatInput from 'components/chat/ChatInput/ChatInput';
import AnalysisPanel, {
  AnalysisPanelTabs
} from 'components/chat/AnalysisPanel/AnalysisPanel';
import { useAppServices } from 'hooks/useAppServices';
import { useAlerts } from 'hooks/useAlerts';
import { downloadFile } from 'util/files';
import { useAuth } from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import { getAllowedTagsForUser } from 'util/tags';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'components/nav/NavigatorMenu/NavigatorMenu';

const ChatTab: React.FC = () => {
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { chatService, dataService } = useAppServices();
  const { userGroups } = useAuth();
  const { sendAlert } = useAlerts();
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat'
  });

  /*
   * ************** State Vars *********************
   */
  const lastMessageRef = useRef<string>('');
  const messageStreamEndRef = useRef<HTMLDivElement | null>(null);

  const [error, setError] = useState<Error>();
  const [isGeneratingResponse, setGeneratingResponse] =
    useState<boolean>(false);

  const [threadId, setThreadId] = useState<string | undefined>();
  const [chatMessages, setChatMessages] = useState<
    [user: ChatMessage, response: ChatResponse][]
  >([]);
  const [streamingResponse, setStreamingResponse] =
    useState<AsyncGenerator<ChatResponseChunk, void>>();
  const [selectedMessage, setSelectedMessage] = useState<number>(0);

  const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<
    AnalysisPanelTabs | undefined
  >();

  /*
   * ************** Hooks *********************
   */
  const { data: account } = useQuery({
    queryKey: ['account'],
    queryFn: () => dataService.getAccount(),
    staleTime: 1800000 // 30 minutes
  });

  // Scroll to the bottom of the chat when new messages come in
  useEffect(
    () => messageStreamEndRef.current?.scrollIntoView({ behavior: 'smooth' }),
    [isGeneratingResponse]
  );

  /*
   * ************** Helper Functions *********************
   */
  const handleSendQuestion = async (message: ChatMessage) => {
    if (!account) {
      console.error('Cannot send question, no account detected');
      setError(new Error(t('message.noAccount')));
    } else {
      lastMessageRef.current = message.content;

      error && setError(undefined);

      try {
        const messages: ChatMessage[] = [];
        chatMessages.forEach((message) => {
          const userMessage = message[0];
          const assistantResponse = message[1];
          const assistantMessage: ChatMessage = {
            role: 'assistant',
            content: assistantResponse.choices[0].message.content
          };

          messages.push(userMessage);
          messages.push(assistantMessage);
        });

        messages.push(message);

        // Get the tags the user has permissions for
        const allowedTags = getAllowedTagsForUser(account, userGroups);

        // Stream the chat response
        const answerStream = chatService.chat(messages, {
          allowedTags,
          threadId
        });
        setStreamingResponse(answerStream);
        setGeneratingResponse(true);
      } catch (e: unknown) {
        const error = e as Error;
        console.error(error);
        setError(error);
        sendAlert({
          severity: 'error',
          message: t('alert.chatError')
        });
      }
    }
  };

  const handleStreamFinished = (response: ChatResponse) => {
    const userMessage = {
      role: 'user',
      content: lastMessageRef.current
    } as ChatMessage;
    setChatMessages([...chatMessages, [userMessage, response]]);
    setGeneratingResponse(false);
    setStreamingResponse(undefined);

    // If the finish_reason is anything other than 'stop', then
    // the current thread was interrupted and must be resumed by
    // re-using the thread ID
    if (response.choices[0].finish_reason !== 'stop') {
      setThreadId(response.choices[0].message.session_state.thread_id);
    } else {
      setThreadId(undefined);
    }
  };

  const handleStreamError = (error: Error) => {
    console.error(error);
    setError(error);
    setStreamingResponse(undefined);
    setGeneratingResponse(false);

    sendAlert({
      severity: 'error',
      message: t('alert.chatError')
    });
  };

  const handleClearChat = () => {
    lastMessageRef.current = '';
    error && setError(undefined);
    setChatMessages([]);
    setThreadId(undefined);
  };

  const handleCitationClicked = async (
    type: 'file' | 'qna',
    fileOptions?: { filePath: string; sourcePath: string; page: string }
  ) => {
    if (type === 'qna') {
      // Reroute to knowledge base page
      navigate(ROUTES.KNOWLEDGE_BASE_ROUTE);
    } else if (fileOptions) {
      const { sourcePath } = fileOptions;
      try {
        const file = await dataService.fetchFile(sourcePath);
        downloadFile(sourcePath, file);
      } catch (_error: unknown) {
        const error = _error as Error;
        console.error(error);
        setError(error);
        sendAlert({
          severity: 'error',
          message: t('alert.downloadError')
        });
      }
    } else {
      console.error('File could not be fetched: No source specified');
      setError(new Error(t('message.fileNotFound')));
      sendAlert({
        severity: 'error',
        message: t('alert.fileNotFoundError')
      });
    }
  };

  const handleSupportingContentClicked = (index: number) => {
    handleToggleTab(AnalysisPanelTabs.SupportingContentTab, index);
  };

  const handleToggleTab = (tab: AnalysisPanelTabs, index: number) => {
    if (activeAnalysisPanelTab === tab && selectedMessage === index) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveAnalysisPanelTab(tab);
    }

    setSelectedMessage(index);
  };

  /*
   * ************** Render *********************
   */
  const renderChatLog = () => {
    return chatMessages.map((message, index) => (
      <Box key={index}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            my: 2
          }}
        >
          <UserChatMessage message={message[0]} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            my: 2
          }}
        >
          <ChatAnswer
            key={index}
            message={message[1]}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onCitationClicked={handleCitationClicked}
            onSupportingContentClicked={() =>
              handleSupportingContentClicked(index)
            }
          />
        </Box>
      </Box>
    ));
  };

  return (
    <>
      <Box
        sx={{
          padding: {
            lg: '0px 100px',
            md: '10px',
            sm: '0px',
            xs: '0px'
          }
        }}
      >
        {!lastMessageRef.current ? (
          <>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 'bolder' }}>
                {t('title')}
              </Typography>
              <Typography variant="body2">{t('instructions')}</Typography>
            </Box>
            <Box
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                padding: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                mt: 4
              }}
            >
              <img
                src={NoChat}
                alt=""
                style={{
                  marginLeft: isSmallScreen ? '0px' : '40px',
                  width: isSmallScreen ? '200px' : '280px',
                  height: isSmallScreen ? '200px' : '260px'
                }}
              />
            </Box>
          </>
        ) : (
          <Box>
            {isGeneratingResponse && streamingResponse && (
              <>
                {renderChatLog()}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <UserChatMessage
                    message={{
                      role: 'user',
                      content: lastMessageRef.current
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    my: 2
                  }}
                >
                  <ChatAnswer
                    stream={streamingResponse}
                    onStreamFinished={handleStreamFinished}
                    onError={handleStreamError}
                    // Do nothing if the user clicks the buttons
                    // while the response is generating
                    onCitationClicked={() => {}}
                    onSupportingContentClicked={() => {}}
                  />
                </Box>
              </>
            )}

            {!isGeneratingResponse && renderChatLog()}

            {error ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    mt: '25px'
                  }}
                >
                  <ChatAnswerError
                    error={error.message}
                    onRetry={() =>
                      handleSendQuestion({
                        role: 'user',
                        content: lastMessageRef.current
                      })
                    }
                  />
                </Box>
              </>
            ) : null}
            <div ref={messageStreamEndRef} />
          </Box>
        )}

        <Box sx={{ mt: '20px' }}>
          <ChatInput
            clearOnSend
            showClearChat
            placeholder={
              isGeneratingResponse
                ? t('message.generating')
                : t('message.enterMessage')
            }
            disabled={isGeneratingResponse}
            onSend={(q) => handleSendQuestion({ role: 'user', content: q })}
            onClearClick={handleClearChat}
          />
        </Box>
      </Box>

      {chatMessages.length > 0 && activeAnalysisPanelTab && (
        <AnalysisPanel
          activeTab={activeAnalysisPanelTab}
          message={chatMessages[selectedMessage][1]}
          onActiveTabChanged={(tab) => handleToggleTab(tab, selectedMessage)}
        />
      )}
    </>
  );
};

export default ChatTab;
