import { useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import ChatTab from './tabs/ChatTab';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import { useTranslation } from 'react-i18next';

const ChatPage: React.FC = () => {
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('chat');

  /*
   * ************** State Vars *********************
   */
  const [value, setValue] = useState(0);

  /*
   * ************** Helper Functions *********************
   */
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  /*
   * ************** Render *********************
   */
  return (
    <Box
      sx={{
        px: {
          md: theme.spacing(3),
          sm: theme.spacing(2),
          xs: theme.spacing(1)
        }
      }}
    >
      <Box
        sx={{
          mt: theme.spacing(6),
          mb: theme.spacing(2)
        }}
      >
        <Typography variant="h2">{t('title')}</Typography>
      </Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.divider
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          textColor={theme.palette.mode === 'light' ? 'primary' : 'inherit'}
          TabIndicatorProps={{
            sx: {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.secondary
                  : ''
            }
          }}
          variant={isSmallScreen ? 'scrollable' : 'standard'}
          scrollButtons={isSmallScreen ? true : false}
        >
          <Tab label={t('tabs.chat')} />
        </Tabs>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          padding: '5px',
          mt: 4
        }}
      >
        <CustomTabPanel value={value} index={0}>
          <ChatTab />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default ChatPage;
