import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  useTheme
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import { FileMetadata } from 'services/api/models';
import { useTranslation } from 'react-i18next';

interface EditDocumentMetadataPopupProps {
  open: boolean;
  metadata: FileMetadata;
  tags: string[];
  onSave: (metadata: FileMetadata) => void;
  onClose: () => void;
}

const EditDocumentMetadataPopup: React.FC<EditDocumentMetadataPopupProps> = (
  props: EditDocumentMetadataPopupProps
) => {
  const { open, metadata, tags, onSave, onClose } = props;

  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'documentStore.viewer.popup.eddit'
  });

  /*
   * ************** State Vars **************
   */
  const [currentTags, setCurrentTags] = useState<string[]>(metadata.tags ?? []);

  /*
   * ************** Hooks **************
   */
  useEffect(() => {
    setCurrentTags(metadata.tags ?? []);
  }, [props]);

  /*
   * ************** Helper Functions **************
   */
  const handleTagsChanged = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    // On autofill we get a stringified value
    setCurrentTags(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSaveClicked = () => {
    onSave({
      tags: currentTags
    });
  };

  /*
   * ************** Render **************
   */
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            my: theme.spacing(2)
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <FormControl sx={{ flexGrow: 0.5 }}>
              <InputLabel id="select-tag-label">{t('tagsLabel')}</InputLabel>
              <Select
                labelId="select-tag-label"
                multiple
                value={currentTags}
                onChange={handleTagsChanged}
                input={<OutlinedInput label={t('tagsLabel')} />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        sx={{
                          backgroundColor:
                            theme.palette.mode === 'light'
                              ? 'rgba(157, 205, 255, 0.49)'
                              : 'rgba(26, 138,255, 0.49)',
                          mx: '2px'
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          startIcon={<CloseIcon />}
          onClick={onClose}
        >
          {t('cancelButtton')}
        </Button>
        <Button
          variant="contained"
          color="info"
          startIcon={<SaveIcon />}
          onClick={handleSaveClicked}
        >
          {t('saveButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDocumentMetadataPopup;
