import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Link
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { ROUTES } from 'components/nav/NavigatorMenu/NavigatorMenu';
import { useAppTheme } from 'hooks/useAppTheme';
import AccountPopover from 'components/header/AccountPopover/AccountPopover';
import ColorModeSwitch from 'components/header/ColorModeSwitch/ColorModeSwitch';

export interface HeaderProps {
  onDrawerToggle: () => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { onDrawerToggle } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { toggleColorMode } = useAppTheme();
  const navigate = useNavigate();

  const handleSettingsIconClick = () => {
    navigate('/account-settings');
  };
  /*
   * ************** Render **************
   */
  return (
    <AppBar
      color="primary"
      position="fixed"
      sx={{
        height: '60px',
        justifyContent: 'center',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
    >
      <Toolbar sx={{ padding: '10px' }}>
        <Grid container alignItems="center">
          <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography color="inherit" variant="h5">
              <Link
                href={ROUTES.HOMEPAGE_ROUTE}
                color="inherit"
                underline="none"
              >
                EMELY.AI
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <ColorModeSwitch
              checked={theme.palette.mode === 'dark'}
              onClick={toggleColorMode}
            />
          </Grid>
          <Grid item>
            <Tooltip title="Alerts • No alerts">
              <IconButton color="inherit" sx={{ padding: 0 }}>
                <NotificationsIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="Settings">
              <IconButton color="inherit" onClick={handleSettingsIconClick}>
                <SettingsOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <AccountPopover />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
