import React, { useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';

interface ChatInputProps {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  showClearChat?: boolean;
  onClearClick?: () => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
  showClearChat,
  onClearClick
}: ChatInputProps) => {
  /*
   * ************** Providers **************
   */
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.input'
  });

  /*
   * ************** State Vars **************
   */
  const [message, setMessage] = useState<string>('');
  const isSendDisabled = disabled || !message.trim();

  /*
   * ************** Helper Functions **************
   */
  const sendQuestion = () => {
    if (isSendDisabled) {
      console.debug('Chat not sent because send is disabled');
      return;
    }

    onSend(message);

    if (clearOnSend) {
      setMessage('');
    }
  };

  const handleMessageChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!value) {
      setMessage('');
    } else {
      setMessage(value);
    }
  };

  const handleEnterPressed = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendQuestion();
    }
  };

  const handleSendClicked = () => {
    sendQuestion();
  };

  /*
   * ************** Render **************
   */

  return (
    <Stack spacing={3}>
      <Box sx={{ position: 'relative', display: 'flex' }}>
        {showClearChat ? (
          <Tooltip title={t('clearChatButtonTooltip')}>
            <IconButton onClick={onClearClick}>
              <RestartAltIcon />
            </IconButton>
          </Tooltip>
        ) : null}
        <TextField
          fullWidth
          size="small"
          disabled={disabled}
          placeholder={placeholder}
          value={message}
          onChange={handleMessageChanged}
          onKeyUp={handleEnterPressed}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSendClicked}
                  disabled={isSendDisabled}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Typography variant="body2" sx={{ textAlign: 'center', mt: 1 }}>
        Emely AI Chat v4.1.0
      </Typography>
    </Stack>
  );
};

export default ChatInput;
