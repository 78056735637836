import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
//import CompanyDetailsLayout from './components/CompanyDetailsLayout';
//import UsedAutomationLayout from './components/UsedAutomationLayout';
//import CommonlyQuestionsLayout from './components/CommonlyQuestionsLayout';

const HomePage: React.FC = () => {
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { t } = useTranslation('home');

  /*
   * ************** Render *********************
   */
  return (
    <>
      <Box
        sx={{
          px: {
            md: theme.spacing(3),
            sm: theme.spacing(2),
            xs: theme.spacing(1)
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            mt: theme.spacing(6),
            mb: theme.spacing(4)
          }}
        >
          <Typography variant="h2">{t('title')}</Typography>
          {/* <Typography variant="body1">Updated 3min ago.</Typography> */}
        </Box>
        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            padding: '20px 20px'
          }}
        >
          {t('comingSoon')}
          {/* <CompanyDetailsLayout /> */}
          {/* <UsedAutomationLayout /> */}
          {/* <CommonlyQuestionsLayout /> */}
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
