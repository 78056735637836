import HomeIcon from '@mui/icons-material/Home';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { AppRoles } from 'config/authConfig';

export const ROUTES = {
  HOMEPAGE_ROUTE: '/home',
  KNOWLEDGE_BASE_ROUTE: '/knowledge-base',
  CHAT_ROUTE: '/chat',
  AUTOMATIONS_ROUTE: '/automations',
  ACCOUNT_SETTINGS_ROUTE: '/account-settings'
};

export type MenuItem = {
  title: string;
  icon: JSX.Element;
  route: string;
  allowedRoles: string[];
};

export const navMenu: MenuItem[] = [
  {
    title: 'home',
    icon: <HomeIcon />,
    route: ROUTES.HOMEPAGE_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.User],
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  },
  {
    title: 'knowledgeBase',
    icon: <PsychologyAltIcon />,
    route: ROUTES.KNOWLEDGE_BASE_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  },
  {
    title: 'chat',
    icon: <AutoAwesomeIcon />,
    route: ROUTES.CHAT_ROUTE,
    allowedRoles: [
      AppRoles[AppRoles.User],
      AppRoles[AppRoles.KnowledgeMaster],
      AppRoles[AppRoles.Administrator]
    ]
  },
  {
    title: 'automations',
    icon: <SettingsSuggestIcon />,
    route: ROUTES.AUTOMATIONS_ROUTE,
    allowedRoles: [AppRoles[AppRoles.Administrator]]
  }
];

export default navMenu;
