import { Box, Container, Typography, useTheme } from '@mui/material';

const AutomationsPage: React.FC = () => {
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();

  /*
   * ************** State Vars *********************
   */

  /*
   * ************** Hooks *********************
   */

  /*
   * ************** Helper Function(s) *********************
   */

  /*
   * ************** Render *********************
   */
  return (
    <Container>
      <Box
        sx={{
          my: theme.spacing(6)
        }}
      >
        <Typography variant="h2">Automations</Typography>
        <Typography>Coming soon!</Typography>
      </Box>
    </Container>
  );
};

export default AutomationsPage;
