import { ChatMessage, ChatResponseChunk, ChatOptions } from './api/models';
import { ChatApiClient } from './api/chatApiClient';
import { NdJsonParserStream } from 'util/stream';

export class ChatService {
  private chatApiClient: ChatApiClient;

  constructor(tenantId: string, apiKey: string) {
    this.chatApiClient = new ChatApiClient(tenantId, apiKey);
  }

  async *chat(
    messages: ChatMessage[],
    options?: {
      threadId?: string;
      allowedTags?: string[];
    }
  ): AsyncGenerator<ChatResponseChunk, void> {
    const chatOptions: ChatOptions = {
      context: {
        top: 5,
        allowed_tags: options?.allowedTags ?? ['public']
      },
      session: {
        thread_id: options?.threadId
      }
    };
    const responseBody = await this.chatApiClient.chatStream(
      messages,
      chatOptions
    );

    // Read the streamed response
    const reader = responseBody
      ?.pipeThrough(new TextDecoderStream())
      .pipeThrough(new NdJsonParserStream())
      .getReader();

    if (!reader) {
      throw new Error('No response body or body is not readable');
    }

    let value: JSON | undefined;
    let done: boolean;
    while ((({ value, done } = await reader.read()), !done)) {
      yield new Promise<ChatResponseChunk>((resolve) => {
        setTimeout(() => {
          resolve(value as unknown as ChatResponseChunk);
        }, 35);
      });
    }
  }
}
