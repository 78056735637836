/* eslint-disable react/prop-types */
import { Navigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Divider,
  Stack,
  Typography,
  alpha,
  createSvgIcon,
  useTheme
} from '@mui/material';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { ROUTES } from 'components/nav/NavigatorMenu/NavigatorMenu';
import { bgGradient } from 'styles/effects';
import { InteractionStatus } from '@azure/msal-browser';
import { useTranslation } from 'react-i18next';

const MicrosoftIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <path
      fill="#ff5722"
      d="M6 6H22V22H6z"
      transform="rotate(-180 14 14)"
    ></path>
    <path
      fill="#4caf50"
      d="M26 6H42V22H26z"
      transform="rotate(-180 34 14)"
    ></path>
    <path
      fill="#ffc107"
      d="M26 26H42V42H26z"
      transform="rotate(-180 34 34)"
    ></path>
    <path
      fill="#03a9f4"
      d="M6 26H22V42H6z"
      transform="rotate(-180 14 34)"
    ></path>
  </svg>,
  'Microsoft'
);

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = () => {
  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const { t } = useTranslation('login');

  /*
   * ************** Render **************
   */
  // If user is already logged in, redirect to the home page
  if (inProgress === InteractionStatus.Login) {
    console.debug('LoginPage rendering: AuthorizationState = LOADING');
    return <div>Loading...</div>;
  } else if (isAuthenticated && inProgress === InteractionStatus.None) {
    console.debug(
      'LoginPage rendering: AuthorizationState = AUTH_SUCCESS. Redirecting to home'
    );
    return <Navigate to={ROUTES.HOMEPAGE_ROUTE} />;
  }

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.75),
          imgUrl: '/static/images/overlay_4.jpg'
        }),
        height: 1
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card sx={{ p: 5, width: 1, maxWidth: 420 }}>
          <Typography variant="h4">{t('title')}</Typography>
          <Typography variant="body2" sx={{ mx: 2, mb: 5 }}>
            {t('instructions')}
          </Typography>
          <Divider sx={{ my: 3 }} />
          <LoadingButton
            startIcon={<MicrosoftIcon />}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            onClick={async () => await instance.loginRedirect()}
          >
            {t('signInButton')}
          </LoadingButton>
        </Card>
      </Stack>
    </Box>
  );
};

export default LoginPage;
